import { Component, TemplateRef, ViewChild } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'global-templates',
  standalone: true,
  imports: [
    FaIconComponent,
  ],
  template: `
    <ng-template #calendarIcon>
      <fa-icon class="text-16" [icon]="['far','calendar-days']" />
    </ng-template>
    <ng-template #angleDownIcon>
      <fa-icon class="text-16" [icon]="['far','angle-down']" />
    </ng-template>
    <ng-template #angleRightIcon>
      <fa-icon class="text-16" [icon]="['far','angle-right']" />
    </ng-template>
  `
})
export class GlobalTemplatesComponent{
  @ViewChild('calendarIcon', { static: true }) calendarIcon!: TemplateRef<void>;
  @ViewChild('angleDownIcon', { static: true }) angleDownIcon!: TemplateRef<void>;
  @ViewChild('angleRightIcon', { static: true }) angleRightIcon!: TemplateRef<void>;
}
