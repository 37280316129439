<div class="file-wrapper flex w-full"
      [class.cursor-pointer]="!disabled()"
      [class.cursor-not-allowed]="disabled()">
  <div class="min-w-50 aspect-square grid place-content-center">
    <fa-icon class="text-20 text-dc-text-primary" [icon]="['fal', iconName() ]"
      [class.opacity-50]="disabled()"
      [class.text-dc-text-primary]="disabled()"
      />
  </div>
  <div class="flex w-full">
    <p class="text-18 leading-22 !my-auto pl-10 text-dc-text-primary"
      [class.opacity-50]="disabled()"
      [class.text-dc-text-primary]="disabled()"
      >
      {{ description() }}
    </p>
  </div>
  <fa-icon class="cursor-pointer grid w-50 aspect-square rounded-10 text-20 leading-25 place-content-center text-dc-text-primary"
      [icon]="['far', 'arrow-right']"
      [class.cursor-pointer]="!disabled()"
      [class.cursor-not-allowed]="disabled()"
      [class.opacity-50]="disabled()"
      [class.text-dc-text-primary]="disabled()"
      />
</div>
