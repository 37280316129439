import { Component, Signal, WritableSignal, inject, signal } from '@angular/core';

import { NzProgressModule } from 'ng-zorro-antd/progress';

import { StepsService } from '@services/steps.service';
@Component({
  selector: 'steps',
  standalone: true,
  imports: [
    NzProgressModule,
  ],
  template: `
    <div class="w-full grid grid-flow-col grid-cols-[repeat(auto-fit,_minmax(10px,_1fr))] gap-10 px-10">
      @for(step of [].constructor(stepsSvc.totalSteps()) ; track $index) {
        <div class="w-full bg-dc-card-background h-[0.4rem] rounded-10"
        [class.bg-dc-success]="$index < stepsSvc.currentStep()"></div>
      }
    </div>
  `,
  styles: `
    :host{
      @apply w-full;
    }
  `
})
export class StepsComponent {
  stepsSvc = inject(StepsService);

}
