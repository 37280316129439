import { Component, InputSignal, inject, input } from '@angular/core';
import { Router } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

import { StepsComponent } from '@shared/components/index';

@Component({
  selector: 'navigation-header',
  standalone: true,
  imports: [
    FaIconComponent,
    StepsComponent
  ],
  template: `
  <div class="flex w-full items-center">
    <fa-icon (click)="navigate()" class="cursor-pointer grid place-content-center
       text-dc-text-primary bg-white w-50 aspect-square rounded-10 text-20 leading-25"
      [icon]="['far', 'arrow-left']" />
    <steps />
  </div>
  <h1 class="font-main-bold text-25 leading-35 mt-20 mb-30 text-left"> {{ title() }} </h1>
  `,
  styles: `
    :host{
      @apply flex w-full flex-col;
    }
  `
})
export class NavigationHeaderComponent {
  #router: Router = inject(Router);

  title: InputSignal<string> = input<string>('Title');
  backwardUrl: InputSignal<string> = input<string>('/inicio');

  navigate(){
    this.#router.navigateByUrl(this.backwardUrl());
  }
}
