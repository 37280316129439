import { Component, InputSignal, input, inject } from '@angular/core';
import { formatCurrency } from '@angular/common';
import { Router } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'product-item',
  standalone: true,
  imports: [
    FaIconComponent,
  ],
  template: `
  <div class="flex w-full relative border-none rounded-10 bg-card shadow-button">
    <span class="min-w-10 h-full rounded-tl-10 rounded-bl-10" [class]="bgColor()"></span>
    <div class="flex flex-col w-full items-center mx-20 pt-15">
      <div class="w-full">
        <p class="text-dc-text-primary font-main-bold text-18 leading-20 mb-5"> {{ title() }} </p>
        <p class="font-main-regular text-16 leading-18">{{ text() }}</p>
      </div>
      <div class="flex flex-nowrap w-full">
        <div class="flex flex-wrap gap-y-20 mt-30 w-full justify-between">
          <span class="flex flex-col text-dc-text-primary">
            <span class="text-15 leading-18 font-main-bold text-center">Desembolso</span>
            <span class="font-main-regular text-16 leading-18">
              {{ formatCurrencyFn(disbursement()) }}
            </span>
          </span>
          <span class="flex flex-col text-dc-text-primary">
            <span class="text-15 leading-18 font-main-bold text-center">Plazo</span>
            <span class="font-main-regular text-16 leading-18">{{ term() }} meses</span>
          </span>
          <span class="flex flex-col text-dc-text-primary pb-15">
            <span class="text-15 leading-18 font-main-bold text-center">Tasa</span>
            <span class="font-main-regular text-16 leading-18">{{ rate() }}%</span>
          </span>
        </div>
        <fa-icon class="cursor-pointer grid place-content-end w-50 h-60 aspect-square rounded-10 text-20 leading-25"
        [icon]="['far', 'arrow-right']" (click)="navigate()">
        </fa-icon>
      </div>
    </div>

  </div>
  `,
  styles: `
    :host{
      @apply flex w-full;
    }
  `
})
export class ProductItemComponent {
  title: InputSignal<string> = input<string>('Title');
  text: InputSignal<string> = input<string>('Text');
  bgColor: InputSignal<string> = input<string>('bg-card_border-left');
  disbursement: InputSignal<string> = input<string>('99999999');
  term: InputSignal<string> = input<string>('12');
  rate: InputSignal<string> = input<string>('99.99');
  link: InputSignal<string> = input<string>('');


  private readonly router = inject(Router);

  navigate(){
    // console.log([this.link()])
      this.router.navigate([this.link()] );
  }

  formatCurrencyFn(value: any) {
    return `$ ${formatCurrency(value, "es-CO", "", "COP", "0.0-0")}`
  }
}
