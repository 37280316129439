import { Component, InputSignal, input } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'file-upload',
  standalone: true,
  imports: [
    FaIconComponent
  ],
  templateUrl: './file-upload.component.html',
  styles: `
  :host{
      @apply flex w-full;
    }
    .file-wrapper{

    }
  `
})
export class FileUploadComponent {

  iconName: InputSignal<IconName> = input.required<IconName>();
  description: InputSignal<string> = input.required<string>();
  disabled: InputSignal<boolean> = input<boolean>(false);
}
