import { CUSTOM_ELEMENTS_SCHEMA, Component, signal, WritableSignal, input, forwardRef, HostListener, InputSignal, ElementRef, ViewChild, AfterViewInit, output, effect } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

// import function to register Swiper custom elements
import { SwiperContainer, register } from "swiper/element/bundle";
import { Swiper, SwiperOptions } from 'swiper/types';
// register Swiper custom elements
register();

@Component({
  selector: 'slide-select',
  standalone: true,
  imports: [],
  template: `
  <div class="py-10">
    <p class="text-center text-dc-text-primary text-15 font-main-medium">
      <!-- {{ labelPosition() == 'preffix' ? label() : '' }} {{ findMinMax().min }} {{ labelPosition() == 'suffix' ? label() : '' }} -->
        Mínimo: {{ labelTop() }}
    </p>
    <div class="swiper-wrapper">
      <swiper-container #swiperRef init="false" [class]="{'disabled': disabled()}" />
    </div>
    <p class="text-center text-dc-text-primary  text-15 font-main-medium">
      <!-- {{ labelPosition() == 'preffix' ? label() : '' }} {{ findMinMax().max }} {{ labelPosition() == 'suffix' ? label() : '' }} -->
      Máximo: {{ labelBottom() }}
    </p>
  </div>
  `,
  styleUrl: './slide-select.component.scss',
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SlideSelectComponent),
      multi: true,
    },
  ],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlideSelectComponent implements AfterViewInit , ControlValueAccessor {
  @ViewChild('swiperRef') swiperRef!: ElementRef;

  #swiperElement: WritableSignal<SwiperContainer | null> = signal<SwiperContainer | null>(null);

  // label: InputSignal<string>  = input.required<string>();
  labelTop: InputSignal<string>  = input.required<string>();
  labelBottom: InputSignal<string>  = input.required<string>();
  labelPosition: InputSignal<'suffix'|'preffix'> = input<'suffix'|'preffix'>('suffix');
  optionList: InputSignal<SlideSelectOption[]>  = input.required<SlideSelectOption[]>();
  valueChange = output<string | number>();

  value: WritableSignal<string | number> = signal<string | number>('');
  disabled: WritableSignal<boolean> = signal<boolean>(false);

  constructor(){
    effect(() => {

      if(this.value()){
        this.onChangeCb?.( this.value() );
        this.valueChange.emit(this.value());
      }

    });
  }

  ngAfterViewInit(): void {
    const swiperConstructor = this.swiperRef.nativeElement;
    const swiperOptions: SwiperOptions = {
      direction: 'vertical',
      slidesPerView: 1,
      initialSlide: 0,
      spaceBetween: 0,
      grabCursor: true,
      mousewheel: true,
      preventClicks: false,
      preventClicksPropagation: false,
      effect: 'coverflow',
      coverflowEffect: {
        rotate: -10,
        stretch: 0,
        scale: 1,
        depth: 56,
        modifier: 1,
        slideShadows: false
      },
      runCallbacksOnInit: true,
      injectStyles: [':host .swiper-vertical { overflow: visible; padding-top: 80px; padding-bottom: 80px; height: 40px;}'],
    }

    if( swiperConstructor ){
      Object.assign(swiperConstructor, swiperOptions);
      this.#swiperElement.set(swiperConstructor as SwiperContainer);

      //* Initialize component
      this.#swiperElement()?.initialize();

      //* Add event listeners
      this.#swiperElement()?.swiper.on('transitionEnd', (e: Swiper) => this.slideChange(e));

      //* Append slides
      this.appendSlides();

      setTimeout(() => {
          if( !this.#swiperElement()?.swiper.destroyed ){
            this.#swiperElement()?.swiper.update();
            this.#swiperElement()?.swiper.updateAutoHeight();
            this.#swiperElement()?.swiper.updateProgress();
            this.#swiperElement()?.swiper.updateSize();
            this.#swiperElement()?.swiper.updateSlides();
            this.#swiperElement()?.swiper.updateSlidesClasses();
          }
      }, 100);
    }
  }

  appendSlides():void{
    this.optionList().forEach( (option: SlideSelectOption, index: number) => {
      const slide = document.createElement('swiper-slide');
      slide.innerText = option.label;
      slide.addEventListener('click', () => this.slideTo(index));
      this.#swiperElement()?.swiper.appendSlide(slide);
    })
  }

  slideChange(swiper: Swiper):void{
    const { activeIndex } = swiper;
    //* Set input value
    this.setValue( this.optionList()[activeIndex].value );
  }

  slideTo(i: number):void{
    this.#swiperElement()?.swiper.slideTo(i);
  }

  slideToImmediately(i: number):void{
    this.#swiperElement()?.swiper.slideTo(i, 0);
  }

  //!! Control Value Accessor Configuration >>>
  onChangeCb?: (value: string | number) => void;
  onTouchedCb?: () => void;

  @HostListener('click') onTouched = () => { this.onTouchedCb?.() };

  writeValue(value: string | number): void {
    this.findValue( value );
  }

  registerOnChange(fn: any): void {
    this.onChangeCb = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCb = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled.set(disabled);

    Promise.resolve().then(() => {
      disabled
      ? this.#swiperElement()?.swiper.disable()
      : this.#swiperElement()?.swiper.enable();
    });
  }

  setValue(value: number | string){
    this.value.set( value );
    // this.onChangeCb?.( value );
    //this.valueChange.emit( value );
  }

  findValue(value: string | number){
    const optionIndex =  this.optionList().findIndex( (option: SlideSelectOption) => option.value === value );

    const indexValid = optionIndex >= 0;

    Promise.resolve().then(() => {
      this.setValue( this.optionList()[ indexValid ? optionIndex : 0 ].value );
      this.slideToImmediately( indexValid ? optionIndex : 0 );
    });
  }

  findMinMax(): { min: number, max: number } {
    const values = this.optionList().map(option => +option.value);

    const min = Math.min(...values);
    const max = Math.max(...values);

    return { min, max };
  }
}

export interface SlideSelectOption {
  label: string;
  value: string | number;
}
